import { DKLabel, Toggle } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantDetails,
  getTenantsDetails
} from "../../redux/slices/booksSlice";
import Tenant from "../../services/tenant";
import { COUNTRY_CODES } from "../../constants/Constant";
import { isPWCustomer } from "../../utility/Utility";

export function QuoteApprovalLockPopupSetting(props) {
  const tenantDetails = useAppSelector(getTenantsDetails);
  const isCountryUS =
    tenantDetails?.country === COUNTRY_CODES.US && !isPWCustomer();
  const [toggle, setToggle] = useState(
    tenantDetails?.additionalSettings?.QUOTE_APPROVAL_LOCK_ENABED || false
  );
  const dispatch = useAppDispatch();

  const onToggle = async () => {
    setToggle(!toggle); // Toggle the state
    await onSave(!toggle); // Call onSave with the new toggle value
  };

  const onSave = async (newValue) => {
    try {
      let additionalSettings = { QUOTE_APPROVAL_LOCK_ENABED: newValue };
      const response = await Tenant.updateTenantDetails({ additionalSettings });
      if (response) {
        // Update Redux state and close the popup
        dispatch(fetchTenantDetails({}));
        props?.onClose?.();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="row justify-content-between border-m border-radius-m p-r mt-l">
        <div className="column">
          <DKLabel
            text={`${isCountryUS ? "Estimate" : "Quote"} Approval Lock`}
            className="mr-m fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text={`Approved ${
              isCountryUS ? "estimates" : "quotes"
            } or approval required drafts will be locked to prevent edits. You can unlock ${
              isCountryUS ? "estimates" : "quotes"
            } from menu or reject drafts for allowing edits and resubmit for approval.`}
          />
        </div>
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
        </div>
      </div>
    </div>
  );
}

export default QuoteApprovalLockPopupSetting;
