import {
  DKButton,
  DKCheckMark,
  DKLabel,
  removeLoader,
  showLoader,
  DKIcons,
  DKIcon
} from "deskera-ui-library";
import { useEffect, useState, useCallback } from "react";
import {
  DEAL_COLUMN_FIELDS,
  DEFAULT_TICKET_FIELD_ORDER,
  MODULES,
  US_COUNTRY_CODE
} from "../../constants/Constant";
import { TICKET_FIELD_KEYS_WITH_DETAIL } from "../../constants/Enum";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import InitialApiManager from "../../managers/InitialApiManager";
import { COLUMN_CODE, TABLES, TableManger } from "../../managers/TableManger";
import { IColumn } from "../../model/Table";
import { useAppSelector } from "../../redux/hooks";
import { getTenantsDetails } from "../../redux/slices/booksSlice";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { store } from "../../redux/store";
import PermissionService from "../../services/common/permission";
import Table from "../../services/table";
import Utility, { getCapitalized, isPWCustomer } from "../../utility/Utility";
import { SUB_SETTING_SCREEN } from "../../constants/Constant";
import { isViewportMobile } from "../../utility/GetViewportSize";
import NoRecordFound from "../common/NoRecordFound";
import BooksService from "../../services/books";
export const allModuleKeys = [
  TABLES.ACCOUNT,
  TABLES.CONTACT,
  TABLES.DEAL,
  TABLES.ACTIVITY,
  TABLES.TICKET,
  TABLES.QUOTE_SETTINGS
];
const FieldMandatoryPopup = (props: any) => {
  const { currentScreen, changeSubRightPanel } = props;
  const defaultMandatoryFields = {
    [TABLES.ACCOUNT]: [],
    [TABLES.CONTACT]: [],
    [TABLES.DEAL]: [],
    [TABLES.ACTIVITY]: [],
    [TABLES.TICKET]: [],
    [TABLES.QUOTE_SETTINGS]: []
  };
  const [displayModules, setDisplayModules] = useState(allModuleKeys);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [selectedSectionKey, setSelectedSection] = useState(TABLES.ACCOUNT);
  const [moduleFormFields, setModuleFormFields] = useState({});
  const [originalModuleFormFields, setOriginalModuleFormFields] = useState(
    defaultMandatoryFields
  );
  const [selectedFields, setSelectedFields] = useState([]);
  const tenantDetails = useAppSelector(getTenantsDetails);
  const crmSettingDetails = useAppSelector(selectCRMSettings());

  useEffect(() => {
    updateModuleFields();
  }, [currentScreen]);
  useEffect(() => {
    if (!isViewportMobile()) {
      changeSubRightPanel(SUB_SETTING_SCREEN.FIELDMAND_OPTIONS);
    }
  }, []);
  useEffect(() => {
    if (currentScreen === SUB_SETTING_SCREEN.FIELDMAND_OPTIONS) {
      fetchModuleFields();
    }
  }, [selectedSectionKey, moduleFormFields, currentScreen]);

  const updateModuleFields = () => {
    let moduleFields = {},
      permissionModules = [];
    const _permissionService = PermissionService.getInstance();

    allModuleKeys.forEach((modules) => {
      const modulePermissions = _permissionService.getUserPermissionByModule(
        _permissionService.getUpdatedModuleName(modules)
      );
      if (
        modulePermissions.includes(USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS)
      ) {
        permissionModules.push(modules);
      }
    });

    setDisplayModules(permissionModules);

    permissionModules?.forEach((module) => {
      let defaultRequired =
        module !== TABLES.TICKET && module !== TABLES.QUOTE_SETTINGS
          ? Utility.deepCloneObject(
              TableManger.getTableFilteredColumns(
                module,
                (column: IColumn) => {
                  return column.required;
                },
                false
              )
            )
          : [];
      let fields =
        module !== TABLES.TICKET
          ? Utility.deepCloneObject(
              TableManger.getTableFilteredColumns(
                module,
                (column: IColumn) => {
                  return (
                    column.editable && column.uiVisible && column.systemField
                  );
                },
                false
              )
            )
          : [];

      defaultMandatoryFields[module] =
        defaultRequired.map((field) => field.columnCode) || [];

      if (module === TABLES.DEAL) {
        const getDealColumns = TableManger.getTableColumns(TABLES.DEAL);
        let defaultColumnForDeal = getDealColumns.filter((col) =>
          [
            COLUMN_CODE.DEAL.CONTACT_ID,
            COLUMN_CODE.DEAL.PIPELINE_ID,
            COLUMN_CODE.DEAL.STAGE_ID,
            COLUMN_CODE.DEAL.REASON
          ].includes(col.columnCode)
        );

        if (!tenantDetails.multicurrencyEnabled) {
          let currencyIndex = fields.findIndex(
            (field) => field.columnCode === COLUMN_CODE.DEAL.CURRENCY_CODE
          );
          fields.splice(currencyIndex, 1);
        }

        fields = [...fields, ...defaultColumnForDeal];
      }

      if (module === TABLES.ACTIVITY) {
        const getDealColumns = TableManger.getTableColumns(TABLES.ACTIVITY);
        let defaultColumnForDeal = getDealColumns.filter((col) =>
          [
            COLUMN_CODE.ACTIVITY.START_DATE,
            COLUMN_CODE.ACTIVITY.END_DATE
          ].includes(col.columnCode)
        );
        fields = [...fields, ...defaultColumnForDeal];
      }

      if (module === TABLES.TICKET) {
        fields = DEFAULT_TICKET_FIELD_ORDER.filter(
          (fields) =>
            ![
              TICKET_FIELD_KEYS_WITH_DETAIL.CREATED_AT,
              TICKET_FIELD_KEYS_WITH_DETAIL.RESOLVED_AT,
              TICKET_FIELD_KEYS_WITH_DETAIL.CREATED_BY,
              TICKET_FIELD_KEYS_WITH_DETAIL.CLOSED_AT
            ].includes(fields?.id)
        );
        if (!Utility.isEmptyObject(crmSettingDetails?.requiredColumns)) {
          let ticketRequiredColumns =
            crmSettingDetails?.requiredColumns?.filter(
              (obj) => obj.objectType === "TICKET"
            );
          if (
            !Utility.isEmptyObject(ticketRequiredColumns?.[0]?.requiredColIds)
          ) {
            fields = fields.map((obj: any) => {
              obj["requiredByUser"] =
                ticketRequiredColumns?.[0]?.requiredColIds?.includes(obj?.id) ??
                false;
              return obj;
            });
          } else {
            fields = fields.map((obj: any) => {
              obj["requiredByUser"] = false;
              return obj;
            });
          }
        }
      } else if (module === TABLES.QUOTE_SETTINGS) {
        fields = [];
        fields.push(DEAL_COLUMN_FIELDS);
      }
      if (!Utility.isEmptyObject(crmSettingDetails?.requiredColumns)) {
        let requiredColumns = crmSettingDetails?.requiredColumns?.filter(
          (obj) => obj.objectType === module
        );
        if (!Utility.isEmptyObject(requiredColumns?.[0]?.requiredColIds)) {
          fields = fields.map((obj: any) => {
            obj["requiredByUser"] =
              requiredColumns?.[0]?.requiredColIds?.includes(obj?.id) ?? false;
            return obj;
          });
        }
      }

      // Update the 'requiredByUser' property for the 'Link to Deal' column
      if (module === TABLES.QUOTE_SETTINGS) {
        const quoteRequiredColumns = crmSettingDetails?.requiredColumns?.find(
          (obj) => obj.objectType === MODULES.QUOTE
        );
        fields[0].requiredByUser =
          quoteRequiredColumns?.requiredColIds?.includes(
            COLUMN_CODE.BOOKS_QUOTE.CRM3DEAL
          ) ?? false;
      }
      moduleFields[module] = fields;
    });
    setModuleFormFields(moduleFields);
    setOriginalModuleFormFields(
      Utility.deepCloneObject(defaultMandatoryFields)
    );
  };

  const fetchModuleFields = () => {
    let fields = moduleFormFields[selectedSectionKey];
    setSelectedFields(fields);
  };

  const getButtons = () => {
    return (
      <div className="row width-auto mt-l">
        {displayModules?.length > 0 &&
          currentScreen === SUB_SETTING_SCREEN.FIELDMAND_OPTIONS && (
            <DKButton
              title="Save"
              className="bg-button text-white"
              disabled={disableSaveButton}
              onClick={() => saveRequiredFields()}
            />
          )}
      </div>
    );
  };

  const width = isViewportMobile() ? "100%" : 150;
  const getModuleName = (module) => {
    if (module === TABLES.QUOTE_SETTINGS) {
      const isCountryUS = [US_COUNTRY_CODE].includes(
        BooksService.getTenantCountry()
      );
      return isCountryUS && !isPWCustomer() ? "Estimate" : "Quote";
    } else {
      return getCapitalized(module.toLowerCase());
    }
  };
  const getModuleSelectorPanel = () => {
    return (
      <div
        className={` ${isViewportMobile() ? "mt-l mb-l pb-l" : ""} z-index-1 border-m-b hide-scroll-bar`}
        style={{ width, maxHeight: "100%", overflowY: "auto" }}
      >
        {displayModules?.map((key) => {
          let title = getModuleName(key);
          return (
            <div
              className={`row justify-content-between cursor-hand ${isViewportMobile() ? "p-v-xs" : ""}`}
              onClick={() => onModuleChange(key)}
            >
              <DKLabel
                text={title}
                className={
                  " parent-width p-v-s pl-xs " +
                  (key === selectedSectionKey && !isViewportMobile()
                    ? "fw-m text-app listPickerBG"
                    : "")
                }
                style={{
                  backgroundColor:
                    key === selectedSectionKey && !isViewportMobile()
                      ? "#f4f4f6"
                      : ""
                }}
              />
              {isViewportMobile() && (
                <DKIcon src={DKIcons.ic_arrow_right2} className="ic-xs-2" />
              )}
              {key === selectedSectionKey && !isViewportMobile() && (
                <div
                  className="bg-button parent-height"
                  style={{ width: 2, height: 30 }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const onChangeRequiredField = (row, requiredValue) => {
    let moduleFormFieldsData = { ...moduleFormFields };
    let formFields = moduleFormFieldsData[selectedSectionKey];
    formFields = formFields.map((field) => {
      if (
        selectedSectionKey !== TABLES.TICKET &&
        field.columnCode === row.columnCode
      ) {
        return { ...field, requiredByUser: requiredValue };
      }
      if (selectedSectionKey === TABLES.TICKET && field.id === row.id) {
        return { ...field, requiredByUser: requiredValue };
      }
      return field;
    });
    moduleFormFieldsData[selectedSectionKey] = formFields;
    setModuleFormFields(moduleFormFieldsData);
  };

  const checkDefaultField = (col) => {
    return originalModuleFormFields[selectedSectionKey]?.includes(
      col.columnCode
    );
  };
  const paddingLeft = isViewportMobile() ? 0 : 16;
  const getRequiredFieldTogglePanel = () => {
    return (
      <div
        className={`${isViewportMobile() ? "p-m" : ""} z-index-1 border-m-b parent-width `}
        style={{
          paddingLeft
        }}
      >
        <div className="row pt-s pb-s justify-content-between parent-width">
          <DKLabel
            text={`${getModuleName(selectedSectionKey)}`}
            className=" fw-m"
          />
          <div style={{ width: 60 }} className=" row justify-content-end">
            <DKLabel
              text="Mark as Required"
              className=" fw-m  text-gray text-align-center"
            />
          </div>
        </div>

        {selectedFields?.map((action, index) => {
          let defaultField = checkDefaultField(action);
          let isRequired = action.required
            ? action.required
            : action.requiredByUser;
          return (
            <div
              className={`p-v-s ${!isViewportMobile() && "listPickerBG"} row justify-content-between ${
                defaultField ? "cursor-not-allowed" : "cursor-hand"
              }`}
              style={{
                paddingLeft: 2,
                paddingRight: 2
              }}
              onClick={() => {
                if (defaultField) {
                  return;
                }
                onChangeRequiredField(action, !isRequired);
              }}
            >
              <DKLabel
                text={`${action.name} ${defaultField || isRequired ? "*" : ""}`}
              />
              <div className="pr-r">
                <DKCheckMark
                  isSelected={defaultField ? true : isRequired}
                  disabled={defaultField}
                  color={defaultField ? "bg-gray" : "bg-button"}
                  onClick={(e) => {
                    if (defaultField) {
                      return;
                    }
                    e?.stopPropagation();
                    onChangeRequiredField(action, !isRequired);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const onModuleChange = (key: string) => {
    setSelectedSection(key);
    changeSubRightPanel(SUB_SETTING_SCREEN.FIELDMAND_OPTIONS);
  };

  const createPayload = () => {
    let payload = [];
    allModuleKeys.forEach((module) => {
      let getChangeValueKey = getRequiredFieldKey(
        moduleFormFields[module],
        module
      );
      let defaultKeys = getDefaultValueKeys(module);

      let combinedKeys = [
        ...new Set([...defaultKeys, ...getChangeValueKey])
      ].filter((key) => key != null);

      if (
        combinedKeys.length > 0 ||
        (module === TABLES.QUOTE_SETTINGS && !isLinkToDealRequired())
      ) {
        payload.push({
          objectType: module.toUpperCase(),
          isObjectTypeWithoutTable: module === TABLES.QUOTE_SETTINGS,
          requiredColIds: combinedKeys.length > 0 ? combinedKeys : []
        });
      }

      if (combinedKeys.length === 0 && module === TABLES.TICKET) {
        payload.push({
          objectType: module.toUpperCase(),
          isObjectTypeWithoutTable: module === TABLES.QUOTE_SETTINGS,
          requiredColIds: []
        });
      }
    });
    return payload;
  };

  const isLinkToDealRequired = () => {
    const quoteFields = moduleFormFields[TABLES.QUOTE_SETTINGS];
    return quoteFields?.length > 0 && quoteFields[0]?.requiredByUser;
  };

  const getRequiredFieldKey = (formFields, module) => {
    const changedValues = [];
    formFields?.forEach((field) => {
      if (field.requiredByUser) {
        if (module !== TABLES.TICKET) {
          changedValues.push(field.key || field.columnCode);
        } else {
          changedValues.push(field.id);
        }
      }
    });
    return changedValues;
  };

  const getDefaultValueKeys = (module) => {
    let defaultCol = originalModuleFormFields[module];
    let moduleDefaultKeys = [];
    defaultCol.forEach((field) => {
      let key = TableManger.getColumnId(module, field);
      if (key != null) {
        moduleDefaultKeys.push(key);
      }
    });
    return moduleDefaultKeys;
  };

  const saveRequiredFields = async () => {
    let payload = createPayload();
    showLoader("Saving settings...");
    Table.updateRequiredFields(payload)
      .then((data) => {
        InitialApiManager.getTableData();
        store.dispatch(fetchCRMSettings({}));
        removeLoader();
      })
      .catch((err) => {
        removeLoader();
        console.error("error::", err);
      });
  };

  return (
    <div className="column flex-1 parent-size ">
      {isViewportMobile() ? (
        currentScreen === SUB_SETTING_SCREEN.FIELDMAND_MODULE_SELECTOR ? (
          getModuleSelectorPanel()
        ) : (
          getRequiredFieldTogglePanel()
        )
      ) : (
        <>
          <div className="mt-l mb-s parent-width row align-items-stretch border-m-b">
            {displayModules?.length > 0 && getModuleSelectorPanel()}
            {displayModules?.length > 0 && (
              <div
                className="parent-height bg-gray2 column"
                style={{ width: 1, marginLeft: -2 }}
              />
            )}
            {displayModules?.length > 0 && getRequiredFieldTogglePanel()}
            {displayModules?.length === 0 && (
              <div className="column parent-size">
                <NoRecordFound
                  title={" "}
                  subTitle={`Looks like you don't have permission`}
                />
              </div>
            )}
          </div>
        </>
      )}
      {getButtons()}
    </div>
  );
};

export default FieldMandatoryPopup;
