import { DKLabel, Toggle } from "deskera-ui-library";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchTenantDetails,
  getTenantsDetails
} from "../../redux/slices/booksSlice";
import Tenant from "../../services/tenant";
import { COUNTRY_CODES } from "../../constants/Constant";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { isPWCustomer } from "../../utility/Utility";

export default function QuoteEditBlockMultiUser(props) {
  const tenantDetails = useAppSelector(getTenantsDetails);
  const isCountryUS =
    tenantDetails?.country === COUNTRY_CODES.US && !isPWCustomer();
  const [toggle, setToggle] = useState(
    tenantDetails?.additionalSettings?.[
      USER_ACTION_TYPES.QUOTE_EDIT_BLOCK_MULTI_USER
    ] || false
  );
  const dispatch = useAppDispatch();

  const onToggle = async () => {
    setToggle(!toggle); // Toggle the state
    await onSave(!toggle); // Call onSave with the new toggle value
  };

  const onSave = async (newValue) => {
    try {
      let additionalSettings = {
        [USER_ACTION_TYPES.QUOTE_EDIT_BLOCK_MULTI_USER]: newValue
      };
      const response = await Tenant.updateTenantDetails({ additionalSettings });
      if (response) {
        // Update Redux state and close the popup
        dispatch(fetchTenantDetails({}));
        props?.onClose?.();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="row justify-content-between border-m border-radius-m p-r mt-l">
        <div className="column">
          <DKLabel
            text={`${isCountryUS ? "Estimate" : "Quote"} Block Edit By Multi Users`}
            className="mr-m fw-m mb-xs"
          />
          <DKLabel
            className="text-gray"
            text={`Prevent editing and deleting ${
              isCountryUS ? "an Estimate" : "a Quote"
            } by multiple org users at once. ${
              isCountryUS ? "Estimate" : "Quote"
            } will be locked once any user opens the same for editing and will be unlocked once saved or closed. <i>Manual Checkout or Edit lock reset</i> can be done via context menu as well.`}
          />
        </div>
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle isOn={toggle} onChange={onToggle} color="bg-green" />
        </div>
      </div>
    </div>
  );
}
