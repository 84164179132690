import { useState, useEffect, useCallback } from "react";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { DKLabel, Toggle } from "deskera-ui-library";
import UserManager from "../../managers/UserManager";
import { US_COUNTRY_CODE } from "../../constants/Constant";
import { updateTenantSettings } from "../../services/tenant";
import { isPWCustomer } from "../../utility/Utility";

const QuoteActionSetting = () => {
  const { crmQuoteSetting } = useAppSelector(selectCRMSettings());
  const [quoteSettingState, setQuoteSettingState] = useState({
    isPrintOptionDisabled: crmQuoteSetting?.isPrintOptionDisabled ?? false,
    isDesignOptionDisabled: crmQuoteSetting?.isDesignOptionDisabled ?? false,
    isEmailOptionDisabled: crmQuoteSetting?.isEmailOptionDisabled ?? false,
    isWhatsappOptionDisabled: crmQuoteSetting?.isWhatsappOptionDisabled ?? false
  });
  const isCountryUS =
    UserManager.getUserCountry() === US_COUNTRY_CODE && !isPWCustomer();
  const dispatch = useAppDispatch();

  const onToggle = async (key: string) => {
    setQuoteSettingState((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const onSave = useCallback(async () => {
    try {
      if (
        crmQuoteSetting?.isPrintOptionDisabled ===
          quoteSettingState.isPrintOptionDisabled &&
        crmQuoteSetting?.isDesignOptionDisabled ===
          quoteSettingState.isDesignOptionDisabled &&
        crmQuoteSetting?.isEmailOptionDisabled ===
          quoteSettingState.isEmailOptionDisabled &&
        crmQuoteSetting?.isWhatsappOptionDisabled ===
          quoteSettingState.isWhatsappOptionDisabled
      )
        return;

      const payload = {
        crmQuoteSetting: quoteSettingState
      };
      await updateTenantSettings(payload);
      dispatch(fetchCRMSettings({}));
    } catch (error) {
      console.error(error);
    }
  }, [crmQuoteSetting, quoteSettingState, dispatch]);

  useEffect(() => {
    onSave();
  }, [onSave]);

  return (
    <div className="column parent-width flex-1 border-m border-radius-m p-r mt-l">
      <DKLabel
        text={`${isCountryUS ? "Estimate" : "Quote"} Actions Setting`}
        className="fw-m"
      />
      <DKLabel
        text={`Select actions to hide when ${isCountryUS ? "Estimate" : "Quote"} is Pending for Approval or Rejected`}
        className="text-gray mt-xs"
      />
      <div className="row justify-content-between mt-l">
        <DKLabel text={`Design`} className="mr-m fw-m" />
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle
            isOn={quoteSettingState.isDesignOptionDisabled}
            onChange={() => onToggle("isDesignOptionDisabled")}
            color="bg-green"
          />
        </div>
      </div>
      <div className="row justify-content-between mt-s">
        <DKLabel text={`Print`} className="mr-m fw-m" />
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle
            isOn={quoteSettingState.isPrintOptionDisabled}
            onChange={() => onToggle("isPrintOptionDisabled")}
            color="bg-green"
          />
        </div>
      </div>
      <div className="row justify-content-between mt-s">
        <DKLabel text={`Email`} className="mr-m fw-m" />
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle
            isOn={quoteSettingState.isEmailOptionDisabled}
            onChange={() => onToggle("isEmailOptionDisabled")}
            color="bg-green"
          />
        </div>
      </div>
      <div className="row justify-content-between mt-s">
        <DKLabel text={`Whatsapp`} className="mr-m fw-m" />
        <div
          className="row justify-content-end pl-xs ml-l"
          style={{ width: 75 }}
        >
          <Toggle
            isOn={quoteSettingState.isWhatsappOptionDisabled}
            onChange={() => onToggle("isWhatsappOptionDisabled")}
            color="bg-green"
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteActionSetting;
