import { DKLabel, Toggle, showAlert } from "deskera-ui-library";
import { useEffect, useState } from "react";
import { COUNTRY_CODES, SETTING_MODULE } from "../../constants/Constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility, { isPWCustomer } from "../../utility/Utility";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { getTenantsDetails } from "../../redux/slices/booksSlice";
import { TABLES } from "../../managers/TableManger";

export interface IContactTabVisibility {
  // onClose: () => void;
}

export default function ContactTabVisibility(props: IContactTabVisibility) {
  const dispatch = useAppDispatch();
  const crmSettings = useAppSelector(selectCRMSettings());
  const tenantDetails = useAppSelector(getTenantsDetails);
  const isCountryUS =
    tenantDetails?.country === COUNTRY_CODES.US && !isPWCustomer();
  const [quoteToggle, setQuoteToggle] = useState(false);
  const [invoiceToggle, setInvoiceToggle] = useState(false);
  const settingPermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, SETTING_MODULE)
  );

  const saveSequenceCodeSettings = async (table: string) => {
    try {
      let payload = {};
      if (table === TABLES.BOOKS_QUOTE) {
        payload = {
          crmContactSetting: {
            crmTabVisibilityContactSetting: {
              estimatesTabIsHidden: !quoteToggle
            }
          }
        };
      }
      if (table === TABLES.BOOKS_INVOICE) {
        payload = {
          crmContactSetting: {
            crmTabVisibilityContactSetting: {
              invoicesTabIsHidden: !invoiceToggle
            }
          }
        };
      }
      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        if (table === TABLES.BOOKS_QUOTE) {
          setQuoteToggle(!quoteToggle);
        }
        if (table === TABLES.BOOKS_INVOICE) {
          setInvoiceToggle(!invoiceToggle);
        }
        showAlert(
          "Updated successfully",
          "Contact tab visibility setting saved successfully"
        );
        dispatch(fetchCRMSettings({}));
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    setQuoteToggle(
      crmSettings?.crmContactSetting?.crmTabVisibilityContactSetting
        ?.estimatesTabIsHidden ?? false
    );
    setInvoiceToggle(
      crmSettings?.crmContactSetting?.crmTabVisibilityContactSetting
        ?.invoicesTabIsHidden ?? false
    );
  }, []);

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between mb-s">
          <div className="column">
            <DKLabel
              text={`Contact tab visibility`}
              className="mr-m mb-xs fw-m"
            />
            <DKLabel
              className="text-gray text-ellipsis"
              text="You can disable tab"
            />
          </div>
          <div className="ml-l"></div>
        </div>
        {settingPermissions[
          USER_ACTION_TYPES.CONTACT_ESTIMATES_TAB_VISIBLE
        ] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="row">
              <DKLabel
                text={`${isCountryUS ? "Estimate" : "Quote"}`}
                className="mb-xs fw-m"
              />
            </div>
            <div className="ml-l">
              <Toggle
                isOn={quoteToggle}
                onChange={() => {
                  // setQuoteToggle(!quoteToggle);
                  saveSequenceCodeSettings(TABLES.BOOKS_QUOTE);
                }}
                className=""
                color="bg-green"
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.CONTACT_INVOICES_TAB_VISIBLE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="row">
              <DKLabel text={`Invoice`} className="mb-xs fw-m" />
            </div>
            <div className="ml-l">
              <Toggle
                isOn={invoiceToggle}
                onChange={() => {
                  // setInvoiceToggle(!invoiceToggle);
                  saveSequenceCodeSettings(TABLES.BOOKS_INVOICE);
                }}
                className=""
                color="bg-green"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
