import { Component } from "react";
import ReactDOM from "react-dom";
import {
  DKLabel,
  DKButton,
  Toggle,
  DKInput,
  DKCheckMark,
  showAlert,
  showLoader,
  removeLoader,
  INPUT_VIEW_DIRECTION
} from "deskera-ui-library";
import {
  RESTRICTED_MODULE_TITLE,
  MODULE_WISE_PERMISSION_TITLE,
  USER_ACTION_TYPES
} from "../../../constants/Permission";
import Utility, { getCapitalized } from "../../../utility/Utility";
import { IRole } from "../../../model/Roles";
import { store } from "../../../redux/store";
import {
  createRole,
  fetchLoggedInUserRole,
  updateRole
} from "../../../redux/slices/rolesPermissionSlice";
import Popup from "../../common/Popup";
import { isViewportMobile } from "../../../utility/GetViewportSize";
import UserManager from "../../../managers/UserManager";
import { US_COUNTRY_CODE } from "../../../constants/Constant";
import { INTL_KEYWORDS } from "../../../constants/CountriesAndStates";
interface ICreateRoleProps {
  roleData?: IRole;
  onCancel?: () => void;
  onSave: (roleData: IRole) => void;
}

interface ICreateRoleState {
  selectedSectionKey: string;
  roleTitle: string;
  rolePermissions: {
    objectType: string;
    permissions: { key: string; title: string; isOn: boolean }[];
    allPermissionsEnabled: boolean;
  }[];
  enableAllModulePermissions: boolean;
  disableSaveButton: boolean;
  canValidate: boolean;
}

class CreateRole extends Component<ICreateRoleProps, ICreateRoleState> {
  allModuleKeys = Object.keys(MODULE_WISE_PERMISSION_TITLE);

  constructor(props: ICreateRoleProps) {
    super(props);
    this.state = {
      selectedSectionKey: this.allModuleKeys[0],
      roleTitle: this.props.roleData?.name || "",
      canValidate: false,
      rolePermissions: [],
      enableAllModulePermissions: false,
      disableSaveButton: false
    };
  }
  componentDidMount() {
    const allModulePermissions = Utility.makeCopyOfObject(
      MODULE_WISE_PERMISSION_TITLE
    );
    const updatedRolePermissions = this.allModuleKeys.map((objectType) => {
      const savedModulePermission =
        this.props.roleData?.permissions?.data?.find(
          (module) => module.objectType === objectType
        )?.permissions || [];

      const updatedPermissions = Object.keys(
        allModulePermissions[objectType]
      ).map((key) => ({
        key,
        title: allModulePermissions[objectType][key],
        isOn: Boolean(savedModulePermission?.includes?.(key))
      }));

      const isAllPermissionsEnabled = !updatedPermissions.some(
        (action) => !action.isOn
      );
      return {
        objectType,
        permissions: updatedPermissions,
        allPermissionsEnabled: isAllPermissionsEnabled
      };
    });

    const isAllModulePermissionEnabled = !updatedRolePermissions.some(
      (module) => !module.allPermissionsEnabled
    );
    this.setState({
      rolePermissions: updatedRolePermissions,
      enableAllModulePermissions: isAllModulePermissionEnabled
    });
  }

  /** **************** RENDERERS ************************ */
  render() {
    return (
      <div className="column flex-1">
        {this.getHeader()}
        {this.getRoleTitleSection()}
        <div
          className="mt-l mb-s parent-width row align-items-stretch"
          style={{
            height: 400
          }}
        >
          {this.getModuleSelectorPanel()}
          <div
            className=" parent-height bg-gray2 column"
            style={{ width: 1, marginLeft: -2 }}
          />
          {this.getPermissionTogglePanel()}
        </div>
      </div>
    );
  }
  getHeader() {
    return (
      <div className="pt-s row justify-content-between align-items-start">
        <div>
          <DKLabel
            text={`${this.props.roleData?._id ? "Edit" : "Add"} role`}
            className="fw-m fs-m"
          />
          <DKLabel
            text="Apply module level permissions specific to this role"
            className="mt-xs text-gray"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-gray1 mr-r"
            onClick={() => this.props.onCancel?.()}
          />
          {!this.props.roleData?.default && (
            <DKButton
              title="Save"
              className="bg-button text-white"
              disabled={this.state.disableSaveButton}
              onClick={() => this.saveRolePermissions()}
            />
          )}
        </div>
      </div>
    );
  }
  getRoleTitleSection() {
    return (
      <div className="column parent-width mt-l">
        <DKInput
          title="Role title"
          required={true}
          autoFocus={!this.props.roleData?.default}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          value={this.state.roleTitle}
          onChange={this.onRoleTitleChange}
          errorMessage={" "}
          readOnly={this.props.roleData?.default}
          canValidate={this.state.canValidate}
        />
        <div className="row parent-width mt-l justify-content-between">
          <DKCheckMark
            title={
              this.state.enableAllModulePermissions
                ? "Reset access to all modules"
                : "Provide access to all modules"
            }
            className="fw-m"
            isSelected={this.state.enableAllModulePermissions}
            color={"bg-button"}
            disabled={this.props.roleData?.default}
            onClick={() => this.toggleAllPermissions(true)}
          />
          {/* <Toggle
            color="bg-button"
            isOn={this.state.enableAllModulePermissions}
            onChange={() => this.toggleAllPermissions(true)}
          /> */}
        </div>
      </div>
    );
  }
  replaceCountrySpecificKeywords(text: string) {
    if (!text) return "";

    if (UserManager.getUserCountry() === US_COUNTRY_CODE) {
      const keywordsMap = INTL_KEYWORDS[US_COUNTRY_CODE];

      Object.entries(keywordsMap).forEach(([key, replaceValue]) => {
        text = text.replaceAll(key, replaceValue);
      });
    }

    return text;
  }
  getModuleSelectorPanel() {
    return (
      <div
        className=" z-index-1 hide-scroll-bar"
        style={{ width: 150, maxHeight: "100%", overflowY: "auto" }}
      >
        {this.allModuleKeys?.map((key) => {
          let title = getCapitalized(
            RESTRICTED_MODULE_TITLE[key].toLowerCase()
          );
          return (
            <div
              className="row justify-content-between  cursor-hand"
              onClick={() => this.onModuleChange(key)}
            >
              <DKLabel
                text={this.replaceCountrySpecificKeywords(title)}
                className={
                  "listPickerBG parent-width p-v-s " +
                  (key === this.state.selectedSectionKey
                    ? "fw-m text-app "
                    : "")
                }
                style={{
                  backgroundColor:
                    key === this.state.selectedSectionKey ? "#f4f4f6" : ""
                }}
              />
              {key === this.state.selectedSectionKey && (
                <div
                  className="bg-button parent-height"
                  style={{ width: 2, height: 30 }}
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }
  getPermissionTogglePanel() {
    const selectedModulePermissions = this.state.rolePermissions.find(
      (module) => module.objectType === this.state.selectedSectionKey
    );
    return (
      <div
        className=" z-index-1 flex-1 hide-scroll-bar"
        style={{
          paddingLeft: 16,
          maxHeight: "100%",
          overflowY: "auto"
        }}
      >
        <div
          className={`row justify-content-between p-v-s ${this.props.roleData?.default ? "" : "cursor-hand"}`}
          style={{
            paddingLeft: 2,
            paddingRight: 2
          }}
          onClick={() =>
            this.toggleAllPermissions(false, this.state.selectedSectionKey)
          }
        >
          <DKLabel
            text={
              selectedModulePermissions?.allPermissionsEnabled
                ? "Unselect all"
                : "Select all"
            }
            className="fw-m"
            style={{ marginRight: "auto" }}
          />
          <DKCheckMark
            isSelected={selectedModulePermissions?.allPermissionsEnabled}
            color={"bg-button"}
            onClick={(e) => {
              if (this.props.roleData?.default) {
                showAlert(
                  "Operation not allowed!",
                  "You can not edit the default role."
                );
              }
              e?.stopPropagation();
              this.toggleAllPermissions(false, this.state.selectedSectionKey);
            }}
          />
          {/*
          <Toggle
            color="bg-button"
            isOn={selectedModulePermissions?.allPermissionsEnabled}
            onChange={() => this.toggleAllPermissions(false, this.state.selectedSectionKey)}
          /> */}
        </div>
        {selectedModulePermissions?.permissions?.map((action, index) => {
          return (
            <div
              className={`p-v-s listPickerBG row justify-content-between ${this.props.roleData?.default ? "" : "cursor-hand"}`}
              style={{
                paddingLeft: 2,
                paddingRight: 2
              }}
              onClick={() =>
                this.handleActionPermissionToggle(action.key, !action.isOn)
              }
            >
              <DKLabel
                text={this.replaceCountrySpecificKeywords(action.title)}
              />
              <DKCheckMark
                isSelected={action.isOn}
                color={"bg-button"}
                onClick={(e) => {
                  if (this.props.roleData?.default) {
                    showAlert(
                      "Operation not allowed!",
                      "You can not edit the default role."
                    );
                  }
                  e?.stopPropagation();
                  this.handleActionPermissionToggle(action.key, !action.isOn);
                }}
              />
              {/* 
              <Toggle
                color="bg-button"
                isOn={action.isOn}
                onChange={() =>
                  this.handleActionPermissionToggle(action.key, !action.isOn)
                }
              /> */}
            </div>
          );
        })}
      </div>
    );
  }

  /** **************** HANDLERS ************************ */
  onRoleTitleChange = (name: string) => {
    this.setState({
      roleTitle: name,
      canValidate: true
    });
  };
  onModuleChange = (key: string) => {
    this.setState({ selectedSectionKey: key });
  };
  toggleAllPermissions = (
    allModules?: boolean,
    objectType?: string,
    forceToggleStatus?: boolean
  ) => {
    if (this.props.roleData?.default) return;

    const updatedRolePermissions = Utility.makeCopyOfObject(
      this.state.rolePermissions
    );

    let isAllModulesChecked = this.state.enableAllModulePermissions;

    if (allModules) {
      const toggleOn = !this.state.enableAllModulePermissions;
      showAlert(
        `${toggleOn ? "Enable" : "Reset"} all permissions`,
        `Permissions in all modules will get ${toggleOn ? "enabled" : "disabled"} for this role.
        Do you want to continue?`,
        [
          {
            title: "Cancel",
            className: "border-m",
            onClick: () => {}
          },
          {
            title: "Ok",
            className: "bg-button text-white ml-xs",
            onClick: () => {
              updatedRolePermissions.forEach((module) => {
                module.permissions.forEach((permission) => {
                  permission.isOn = toggleOn;
                });
                module.allPermissionsEnabled = toggleOn;
              });

              isAllModulesChecked = toggleOn;

              this.setState({
                rolePermissions: updatedRolePermissions,
                enableAllModulePermissions: isAllModulesChecked
              });
            }
          }
        ]
      );
    } else {
      const moduleToUpdate = updatedRolePermissions.find(
        (module) => module.objectType === objectType
      );
      const toggleOn =
        forceToggleStatus ?? !moduleToUpdate.allPermissionsEnabled;
      moduleToUpdate.permissions.forEach((permission) => {
        permission.isOn = toggleOn;
      });
      moduleToUpdate.allPermissionsEnabled = toggleOn;
      isAllModulesChecked = isAllModulesChecked && toggleOn;
      this.setState({
        rolePermissions: updatedRolePermissions,
        enableAllModulePermissions: isAllModulesChecked
      });
    }
  };
  handleActionPermissionToggle = (key: string, toggle: boolean) => {
    if (this.props.roleData?.default) return;

    const updatedRolePermissions = Utility.makeCopyOfObject(
      this.state.rolePermissions
    );

    const currentModuleData = updatedRolePermissions.find(
      (module) => module.objectType === this.state.selectedSectionKey
    );
    const currentModulePermissions = currentModuleData.permissions;
    const permissionToUpdate = currentModulePermissions.find(
      (permission) => permission.key === key
    );
    permissionToUpdate.isOn = toggle;

    /* Toggle on view permission automatically (only once), on enabling any other permission */
    // if (key !== USER_ACTION_TYPES.REC_VIEW && toggle) {
    //   const viewPermissionToUpdate = currentModulePermissions.find(
    //     (permission) => permission.key === USER_ACTION_TYPES.REC_VIEW
    //   );

    //   if (
    //     viewPermissionToUpdate &&
    //     !viewPermissionToUpdate.viewPermissionAutoEnabled
    //   ) {
    //     viewPermissionToUpdate.isOn = toggle;
    //     viewPermissionToUpdate.viewPermissionAutoEnabled = true;
    //   }
    // }

    currentModuleData.allPermissionsEnabled = !currentModulePermissions.some(
      (permission) => !permission.isOn
    );
    const allModulesChecked =
      this.state.enableAllModulePermissions &&
      currentModuleData.allPermissionsEnabled;

    this.setState({
      rolePermissions: updatedRolePermissions,
      enableAllModulePermissions: allModulesChecked
    });
  };
  saveRolePermissions = async () => {
    if (Utility.isEmptyObject(this.state.roleTitle)) {
      showAlert(
        "Role title required",
        "Please provide a valid role title to proceed."
      );
      return;
    }

    this.setState({ disableSaveButton: true });
    showLoader("Saving role...");

    const permissionsToSave = this.state.rolePermissions.map((module) => ({
      objectType: module.objectType,
      permissions: module.permissions
        .filter((permission) => permission.isOn)
        .map((permission) => permission.key)
    }));

    const newRoleData: any = {
      name: this.state.roleTitle,
      permissions: {
        data: permissionsToSave
      }
    };

    let error = null;

    try {
      let res = null;
      if (this.props.roleData?._id) {
        newRoleData.id = this.props.roleData?._id;
        res = await store.dispatch(updateRole(newRoleData));
        let state: any = store.getState();
        if (newRoleData.id === state?.rolesPermission?.currentUsersRole?._id) {
          store.dispatch(fetchLoggedInUserRole({}));
        }
      } else {
        res = await store.dispatch(createRole(newRoleData));
      }

      if (!Utility.isEmptyObject(res?.error)) {
        error = res.error;
      } else {
        this.props.onSave(res?.payload);
      }
    } catch (err) {
      error = err;
    } finally {
      if (error) {
        showAlert(
          `${this.props.roleData?._id ? "Edit" : "Create"} role failed`,
          `Looks like you don't have permissions or something went wrong, while saving role. Please try again.`
        );
      }

      this.setState({ disableSaveButton: false });
      removeLoader();
    }
  };
}

export const showCreateRolePopup = (props: ICreateRoleProps) => {
  const id = `add-role-popup-${new Date().getTime()}`;
  let div = document.createElement("div");
  div.className = "app-font";
  div.setAttribute("id", id);

  const removePopup = () => {
    ReactDOM.unmountComponentAtNode(div);
    div?.remove();
  };
  const width = isViewportMobile() ? 400 : 500;

  ReactDOM.render(
    <Popup
      className="hide-scroll-bar"
      popupWindowStyles={{
        width,
        maxWidth: "auto",
        maxHeight: "90vh",
        padding: 16
      }}
    >
      <CreateRole
        {...props}
        onSave={(data) => {
          removePopup();
          props.onSave(data);
        }}
        onCancel={() => {
          removePopup();
          props.onCancel?.();
        }}
      />
    </Popup>,
    document.body.appendChild(div)
  );
};

export default CreateRole;
