import {
  DKButton,
  DKIcon,
  DKIcons,
  DKLabel,
  DKSearchBar,
  removeLoader,
  showAlert,
  showLoader
} from "deskera-ui-library";
import React from "react";
import { ConnectedProps, connect } from "react-redux";
import ic_chat from "../../assets/icons/ic_chat_2.png";
import ApiConstants from "../../constants/ApiConstants";
import {
  DEMO_EMAIL,
  DESKERA_CARE_EMAIL,
  SETTING_MODULE
} from "../../constants/Constant";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import AppManager from "../../managers/AppManager";
import RouteManager, { PAGE_ROUTES } from "../../managers/RouteManager";
import { TABLES } from "../../managers/TableManger";
import { fetchTeams } from "../../redux/slices/tenantSlice";
import {
  fetchIndiaMartAccounts,
  fetchJustDialAccounts,
  fetchStripeAccount,
  getAccountsByGateway
} from "../../redux/slices/userPrefSlice";
import ContactService from "../../services/contact";
import NotificationService from "../../services/notification";
import { Stripe } from "../../services/stripe";
import Utility, { isPWCustomer } from "../../utility/Utility";
import ChatEmbedPopup from "../chat/ChatEmbedPopup";
import Popup from "../common/Popup";
import NotificationSettings from "./NotificationSettings";
import TeamPermission from "./TeamPermission";
import UserPermission from "./UserPermission";

import ic_team from "../../assets/icons/ic_team.png";
import UserManager from "../../managers/UserManager";
import ActivitySetting from "./ActivitySetting";
import BookDemoSettings from "./BookDemoSettings";

import ic_no_data from "../../assets/icons/ic_no_data_3.png";
import ic_indiamart from "../../assets/images/indiamartLogo.png";
import ic_justdial from "../../assets/images/justdialLogo.png";
import BooksService from "../../services/books";
import PermissionService from "../../services/common/permission";
import { COMMON_EVENTS, commonCustomEvent } from "../../services/event";
import debounce from "../../utility/Debounce";
import DuplicateCheckSetting from "./DuplicateCheckSetting";
import EventScore from "./EventScore";
import FieldMandatoryPopup, { allModuleKeys } from "./FieldMandatoryPopup";
import { showIndiaMartLeadSyncSetting } from "./IndiaMartLeadSyncSetting";
import { showJustDialLeadSyncSetting } from "./JustDialLeadSyncSetting";

export enum SETTING_CARD_IDS {
  USER_PERMISSION = "user_permission",
  TEAM_PERMISSION = "team_permission",
  DUPLICATE_CHECK = "duplicate_check"
}

export interface ISettingsProps extends PropsFromRedux {
  location?: any;
  history?: any;
}
export interface ISettingsState {
  needFeedbackPopup: boolean;
  isStripeConnected: boolean;
  showEmbedPopup: boolean;
  showNotificationPopup: boolean;
  showUserPermissionsPopup: boolean;
  showTeamPermissionsPopup: boolean;
  showTenantSettingsPopup: boolean;
  disabledBellNotification: any;
  showProfilePopup: boolean;
  showActivityPopup: boolean;
  showBookADemoPopup: boolean;
  showEventScorePopup: boolean;
  showBDRSettingCard: boolean;
  isDeskeraTenant: boolean;
  showDateSettingPopup: boolean;
  showSequenceCodePopup: boolean;
  showEmailAlertPopup: boolean;
  showDuplicateCheckPopup: boolean;
  showEmailSyncPopup: boolean;
  showGroupingPopup: boolean;
  searchText: string;
  cardList: any[];
  showAccountLinkingPopup: boolean;
  showRequiredFieldPopup: boolean;
  showAccountLinkingForDealAndAccountPopup: boolean;
  showUpliftDownLiftPopup: boolean;
  showModuleSelectionPopup: boolean;
  showQuoteSyncPopup: boolean;
  showQuoteApprovalLockPopup: boolean;
}
class Settings extends React.Component<ISettingsProps, ISettingsState> {
  settingOptions = [];
  tenantDetails: any;
  userTenantId: any;
  userId: any;
  userEmail: any;
  constructor(props) {
    super(props);
    this.state = {
      needFeedbackPopup: false,
      isStripeConnected: false,
      showEmbedPopup: false,
      showNotificationPopup: false,
      disabledBellNotification: {},
      showUserPermissionsPopup: false,
      showTeamPermissionsPopup: false,
      showTenantSettingsPopup: false,
      showProfilePopup: false,
      showActivityPopup: false,
      showEventScorePopup: false,
      showBookADemoPopup: false,
      showBDRSettingCard: false,
      isDeskeraTenant: false,
      showDateSettingPopup: false,
      showSequenceCodePopup: false,
      showEmailAlertPopup: false,
      showDuplicateCheckPopup: false,
      showEmailSyncPopup: false,
      showGroupingPopup: false,
      searchText: "",
      cardList: this.getSettingOptions(),
      showAccountLinkingPopup: false,
      showRequiredFieldPopup: false,
      showUpliftDownLiftPopup: false,
      showModuleSelectionPopup: false,
      showQuoteSyncPopup: false,
      showAccountLinkingForDealAndAccountPopup: false,
      showQuoteApprovalLockPopup: false
    };
    this.tenantDetails = UserManager.getUserDetails();
    this.userTenantId = UserManager.getUserTenantID();
    this.userId = UserManager.getUserID();
    this.userEmail = UserManager.getUserEmail();
    if (props.location.search) {
      this.checkFormQueryParams(props);
    }
  }

  requiredFieldPermission = () => {
    let permissionModules = [];
    const _permissionService = PermissionService.getInstance();
    allModuleKeys.forEach((modules) => {
      const modulePermissions = _permissionService.getUserPermissionByModule(
        _permissionService.getUpdatedModuleName(modules)
      );
      if (
        modulePermissions.includes(USER_ACTION_TYPES.CHANGE_REQUIRED_FIELDS)
      ) {
        permissionModules.push(modules);
      }
    });
    return permissionModules;
  };

  getSettingOptions = (
    isDeskeraTenant = this.state?.isDeskeraTenant,
    showBDRSettingCard = this.state?.showBDRSettingCard
  ) => {
    const isCountryUS =
      BooksService.getTenantCountry() === "US" && !isPWCustomer();
    const quoteLabel = isCountryUS ? "Estimate" : "Quote";
    let settingOptions: any = [
      {
        title: "My Profile",
        description:
          "See your profile details and explore our other products too",
        icon: DKIcons.ic_user,
        url: ApiConstants.PRODUCT_URL_GO
      }
    ];

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.USER_PERMISSION
    ] &&
      settingOptions.push({
        cardId: SETTING_CARD_IDS.USER_PERMISSION,
        title: "User Permissions",
        description:
          "Invite new users to your org and manage user permissions.",
        icon: DKIcons.ic_user_2,
        buttonTitle: "Edit Permissions",
        hidden: Utility.isEmptyObject(
          this.props.moduleWisePermissions[TABLES.PERMISSION]
        ),
        onClick: () => this.toggleUserPermissionsPopup(true)
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.TEAM_PERMISSION
    ] &&
      settingOptions.push({
        cardId: SETTING_CARD_IDS.TEAM_PERMISSION,
        title: "Team Permissions",
        description: "Create team and manage team permissions.",
        icon: ic_team,
        buttonTitle: "Edit Permissions",
        hidden:
          !this.props.moduleWisePermissions[TABLES.PERMISSION]?.[
            USER_ACTION_TYPES.ASSIGN_ROLE
          ],
        onClick: () => this.toggleTeamPermissionsPopup(true)
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.ORGANIZATION
    ] &&
      settingOptions.push({
        title: "Organization Settings",
        description: "You can update or modify your organisation details here",
        icon: DKIcons.ic_home,
        url: ApiConstants.PRODUCT_URL_GO + "settings"
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.SUBSCRIPTION_BILLING
    ] &&
      settingOptions.push({
        title: "Subscription / Billing",
        description: "Manage your subscription, change plan and upgrade here",
        icon: DKIcons.ic_cards,
        url: ApiConstants.PRODUCT_URL_GO + "billing"
      });

    settingOptions.push({
      title: "Two-Factor Authentication (2FA)",
      description:
        "Increase your account's security by enabling Two-Factor Authentication (2FA)",
      icon: DKIcons.ic_key,
      url: ApiConstants.PRODUCT_URL_GO + "security"
    });

    settingOptions.push({
      title: "Change Password",
      description: "You can change or modify your password here",
      icon: DKIcons.ic_key_2,
      url: ApiConstants.PRODUCT_URL_GO + "security"
    });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.CURRENCY
    ] &&
      settingOptions.push({
        title: "Currency Settings",
        description: "Change your currency settings here",
        icon: DKIcons.ic_currency,
        buttonTitle: "Change Currency",
        onClick: () => {
          this.setState({ showTenantSettingsPopup: true });
        },
        keywords: ["currency"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.DATE
    ] &&
      settingOptions.push({
        title: "Date Settings",
        description: "Change your date format here",
        icon: DKIcons.ic_calendar,
        buttonTitle: "Change Date Format",
        onClick: () => {
          this.setState({ showDateSettingPopup: true });
        },
        keywords: ["date format", "date setting"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.DUPLICATION
    ] &&
      settingOptions.push({
        cardId: SETTING_CARD_IDS.DUPLICATE_CHECK,
        title: "Duplication settings",
        description: "Manage settings for handling duplicate record in modules",
        icon: DKIcons.ic_copy,
        buttonTitle: "View Settings",
        onClick: () => {
          this.setState({ showDuplicateCheckPopup: true });
        },
        keywords: ["duplicate settings", "duplicate", "duplicate record"]
      });
    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.CONTACT_SEQ_CODE
    ] &&
      settingOptions.push({
        title: "Contact Sequence Code Settings",
        description: "Enable contact sequence code here",
        icon: DKIcons.ic_person,
        buttonTitle: "Sequence Code Settings",
        onClick: () => {
          this.setState({ showSequenceCodePopup: true });
        },
        keywords: ["sequence code", "contact sequence code"]
      });

    if (
      this.props.moduleWisePermissions[TABLES.CONTACT]?.[
        USER_ACTION_TYPES.REC_DELETE
      ] &&
      this.props.moduleWisePermissions[SETTING_MODULE]?.[
        USER_ACTION_TYPES.CLEAR_CONTACTS
      ]
    ) {
      settingOptions.push({
        title: "Clear Contacts",
        description: "This will delete all your contacts permanently",
        icon: DKIcons.ic_delete,
        onClick: () => {
          this.showDeleteContactWarning();
        },
        buttonTitle: "Delete Contacts",
        keywords: ["clear contact", "delete contact"]
      });
    }

    if (
      this.userId === this.tenantDetails?.id &&
      this.userEmail?.includes(DEMO_EMAIL)
    ) {
      settingOptions.push({
        title: "Book-A-Demo Settings",
        description:
          "Select team members & location for setting up a demo call with your leads",
        hidden: true,
        icon: DKIcons.ic_calendar_,
        onClick: () => this.toggleBookDemoPopup(true)
      });
    }

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.PAYMENT_INTEGRATION
    ] &&
      settingOptions.push({
        title: "Integrate Payments",
        description:
          "Integrate your stripe, razorpay, paytm and cashfree accounts to accept payments.",
        icon: DKIcons.ic_currency,
        buttonTitle: "Integrate Payments",
        onClick: () =>
          RouteManager.navigateToPage(PAGE_ROUTES.PAYMENT_INTEGRATION),
        keywords: [
          "Payments",
          "integrate",
          "stripe",
          "razorpay",
          "paytm",
          "cashfree"
        ]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.CHAT
    ] &&
      settingOptions.push({
        title: "Chat Settings",
        description:
          "Add profile details for initial chat details and embed scripts for web pages.",
        icon: ic_chat,
        buttonTitle: "View Details",
        onClick: () => {
          this.setState({
            showProfilePopup: true
          });
        },
        keywords: [
          "chat",
          "display name",
          "welcome message",
          "embed script",
          "color",
          "position",
          "notification sound"
        ]
      });

    settingOptions.push({
      title: "Sync Deskera Activity to Google Calendar",
      description: "Generate import activity url to add in Google Calendar",
      icon: DKIcons.ic_recent,
      buttonTitle: "Generate Url",
      onClick: () => {
        this.setState({
          showActivityPopup: !this.state.showActivityPopup
        });
      },
      keywords: [
        "activity setting",
        "sync activity",
        "import activity",
        "Link to import activity",
        "import in Google calendar",
        "Activity to Google Calendar"
      ]
    });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.NOTIFICATION
    ] &&
      settingOptions.push({
        title: "Notification Settings",
        description:
          "Enable or disable notifications for below sections and events.",
        icon: DKIcons.ic_notification,
        buttonTitle: "View Details",
        onClick: () => {
          this.setState({
            showNotificationPopup: !this.state.showNotificationPopup
          });
        },
        keywords: ["notification settings"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.INDIAMART_INTEGRATION
    ] &&
      settingOptions.push({
        title: "Connect with IndiaMart",
        description: "Sync your leads from your IndiaMart account to CRM.",
        icon: ic_indiamart,
        buttonTitle: Utility.isEmptyObject(this.props.indiaMartAccounts)
          ? "Connect Account"
          : "View Details",
        onClick: async () => {
          showLoader("Fetching IndiaMart integration details..");
          let indiaMartAccounts = this.props.indiaMartAccounts;
          if (Utility.isEmptyObject(indiaMartAccounts)) {
            try {
              const justDialAccountResponse =
                await this.props.fetchIndiaMartAccounts({});
              indiaMartAccounts = justDialAccountResponse.payload;
            } catch (err) {
              indiaMartAccounts = [];
            }
          }

          removeLoader();
          showIndiaMartLeadSyncSetting(
            {
              indiaMartAccount: indiaMartAccounts?.[0] || null
            },
            () => this.props.fetchIndiaMartAccounts({})
          );
        },
        keywords: [
          "connect with indiamart",
          "crm key",
          "sync from",
          "auto sync",
          "indiaMart leads",
          "IndiaMart fields",
          "segment",
          "owners",
          "sender name",
          "sender email",
          "sender mobile",
          "sender company",
          "sender address",
          "sender city",
          "sender state",
          "sender pincode",
          "sender country code",
          "product",
          "category name",
          "message"
        ]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.JUSTDIAL_INTEGRATION
    ] &&
      settingOptions.push({
        title: "Connect with JustDial",
        description: "Sync your leads from your JustDial account to CRM.",
        icon: ic_justdial,
        buttonTitle: Utility.isEmptyObject(this.props.justDialAccounts)
          ? "Connect Account"
          : "View Details",
        onClick: async () => {
          showLoader("Fetching JustDial integration details..");
          let justDialAccounts = this.props.justDialAccounts;
          if (Utility.isEmptyObject(justDialAccounts)) {
            try {
              const justDialAccountResponse =
                await this.props.fetchJustDialAccounts({});
              justDialAccounts = justDialAccountResponse.payload;
            } catch (err) {
              justDialAccounts = [];
            }
          }

          removeLoader();
          showJustDialLeadSyncSetting(
            {
              justDialAccount: justDialAccounts?.[0] || null
            },
            () => this.props.fetchJustDialAccounts({})
          );
        },
        keywords: [
          "JustDial leads",
          "segment",
          "owners",
          "name",
          "email",
          "category",
          "area",
          "branch area",
          "city",
          "mobile",
          "pincode",
          "company",
          "date"
        ]
      });

    if (isDeskeraTenant) {
      settingOptions.push({
        title: "Event Score",
        description: "You can change your event score in setting.",
        icon: DKIcons.ic_settings,
        buttonTitle: "Setting",
        onClick: () => this.setState({ showEventScorePopup: true })
      });
    }

    if (showBDRSettingCard) {
      settingOptions.push({
        title: "BDR Settings",
        description:
          "Manage your business development representatives to be assigned to your website visitors in round robin manner.",
        icon: DKIcons.ic_people,
        onClick: () =>
          RouteManager.navigateToPage(PAGE_ROUTES.SALES_REPRESENTATIVE_SETTING)
      });
    }

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.EMAIL_ALERT
    ] &&
      settingOptions.push({
        title: "Email Alert Settings",
        description: "Manage email alerts for Activities and Deals",
        icon: DKIcons.ic_email,
        buttonTitle: "Email Alert Settings",
        onClick: () => {
          this.setState({ showEmailAlertPopup: true });
        },
        keywords: ["email alert", "alert settings", "contact", "deal"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.EMAIL_SYNC
    ] &&
      settingOptions.push({
        title: "Email sync restart",
        description: "Restart email sync for Contact Deal and Support Email",
        icon: DKIcons.ic_reload,
        buttonTitle: "Email Sync",
        onClick: () => {
          this.setState({ showEmailSyncPopup: true });
        },
        keywords: ["email sync", "alert restart", "contact", "deal", "support"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.PRODUCT_GROUPING
    ] &&
      settingOptions.push({
        title: "Enable Product Grouping",
        description:
          "This will allow you to create sub-section at product level and can be used while selecting product in quotation",
        icon: DKIcons.ic_settings,
        buttonTitle: "Enable Grouping",
        onClick: () => {
          this.setState({ showGroupingPopup: true });
        },
        keywords: ["product grouping", "grouping", "estimate", "group"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.ACCOUNT_LINKAGE
    ] &&
      settingOptions.push({
        title: "Enable Account Linkage",
        description: "Account linkage needed with contact for Deal creation",
        icon: DKIcons.ic_settings,
        buttonTitle: "Enable Linkage",
        onClick: () => {
          this.setState({ showAccountLinkingPopup: true });
        },
        keywords: ["Account Linkage", "linked deal", "linked contact", "linked"]
      });

    this.requiredFieldPermission()?.length > 0 &&
      settingOptions.push({
        title: "Required Fields",
        description: "Mark fields as required for each module",
        icon: DKIcons.ic_settings,
        buttonTitle: "View Details",
        onClick: () => {
          this.setState({ showRequiredFieldPopup: true });
        },
        keywords: ["Required Fields"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.CONTACT_LINKAGE_TO_QUOTE_DEAL
    ] &&
      settingOptions.push({
        title: "Contact Linkage to Quote/Deal",
        description: "Disable contact link in Deal & Quote",
        icon: DKIcons.ic_settings,
        buttonTitle: "Disable Contact",
        onClick: () => {
          this.setState({ showAccountLinkingForDealAndAccountPopup: true });
        },
        keywords: [
          "Account",
          "Contact disable",
          "link in deal",
          "link in quote",
          "Contact"
        ]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.UPLIFT_DONWLIFT_QUOTE
    ] &&
      settingOptions.push({
        title: "Uplift/DownLift Quote Price",
        description:
          "Uplift/DownLift quote product price while creating quotation/estimate",
        icon: DKIcons.ic_settings,
        buttonTitle: "Enable fields",
        onClick: () => {
          this.setState({ showUpliftDownLiftPopup: true });
        },
        keywords: ["Uplift", "DownLift", "price", "quote"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.PRICE_BOOK_CRITERIA_MODULE
    ] &&
      settingOptions.push({
        title: "Price Book Module Selection",
        description:
          "Select module to define price book criteria at the time of price book creation.",
        icon: DKIcons.ic_settings,
        buttonTitle: "Module Selection",
        onClick: () => {
          this.setState({ showModuleSelectionPopup: true });
        },
        keywords: [
          "price book",
          "price",
          "module",
          "contact",
          "account",
          "quote"
        ]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.QUOTATION_SYNC
    ] &&
      settingOptions.push({
        title: `${quoteLabel} Sync Setting`,
        description: `Sync only primary ${quoteLabel.toLowerCase()}s from won deals into books`,
        icon: DKIcons.ic_settings,
        buttonTitle: `Enable ${quoteLabel} Sync`,
        onClick: () => {
          this.setState({ showQuoteSyncPopup: true });
        },
        keywords: ["book", "Sync", "primary", "deal", "won", "quote"]
      });

    this.props.moduleWisePermissions[SETTING_MODULE]?.[
      USER_ACTION_TYPES.QUOTE_APPROVAL_LOCK_ENABED
    ] &&
      settingOptions.push({
        title: `${quoteLabel} Approval Lock`,
        description: `Prevent users to edit ${quoteLabel} when approval pending or when locked after approval`,
        icon: DKIcons.ic_settings,
        buttonTitle: "Enable Approval Lock",
        onClick: () => {
          this.setState({ showQuoteApprovalLockPopup: true });
        },
        keywords: ["book", "approval", "lock", "quote", "estimate"]
      });

    return settingOptions;
  };

  showDeleteContactWarning() {
    let buttons = [
      {
        title: "Cancel",
        className: "bg-gray1 border-m"
      },
      {
        title: "Delete",
        className: "bg-red text-white",
        onClick: () => {
          this.deleteAllContacts();
        }
      }
    ];
    showAlert(
      "Delete all Contacts?",
      "Deleting contacts will delete it permanently.",
      buttons
    );
  }

  deleteAllContacts() {
    ContactService.clearAllContacts().then((res) => {
      // showAlert(
      //   "Contact deleted!",
      //   "All your contacts have been successfully deleted."
      // );
    });

    showAlert(
      "In-progress...",
      "Contact deletion may take sometime. Go back to Contact page and refresh."
    );
  }

  componentDidMount() {
    RouteManager.setPresenter(this);
    this.getNotificationData();
    this.openDefaultCardFromParams();
    this.initCall();
  }
  componentWillReceiveProps(nextProps: any): void {
    if (nextProps.location?.search !== this.props.location?.search) {
      this.openDefaultCardFromParams(nextProps);
    }
  }
  openDefaultCardFromParams = (props: any = this.props) => {
    const cardId = Utility.getQueryParam(props.location, "cardId");
    if (Utility.isEmptyObject(cardId)) return;

    const cardToOpen = this.state.cardList.find(
      (card) => card.cardId === cardId
    );
    cardToOpen?.onClick?.();
  };
  getNotificationData = () => {
    NotificationService.getNotificationSetting().then(
      (response) => {
        this.setState({
          disabledBellNotification: response?.disabledBellNotification,
          showNotificationPopup: false
        });
      },
      (error) => {
        this.setState({ disabledBellNotification: {} });
      }
    );
  };

  initCall = async () => {
    let isDeskeraTenant = false;
    let showBDRSettingCard = false;
    if (Utility.isEmptyObject(this.props.teams)) {
      this.props.fetchTeams();
    }

    if (Utility.isEmptyObject(this.props.indiaMartAccounts)) {
      this.props.fetchIndiaMartAccounts({});
    }

    if (Utility.isEmptyObject(this.props.justDialAccounts)) {
      this.props.fetchJustDialAccounts({});
    }

    this.setState({
      cardList: this.getSettingOptions(isDeskeraTenant, showBDRSettingCard),
      isDeskeraTenant,
      showBDRSettingCard
    });
  };
  onSearch = debounce((value) => {
    this.setState({ searchText: value }, () => {
      let listOfCards = [...this.getSettingOptions()];
      if (!Utility.isEmptyObject(this.state.searchText)) {
        const searchInLow = this.state.searchText.toLowerCase();
        listOfCards = listOfCards?.filter((list: any) => {
          return (
            !list.hidden &&
            (list.title?.toLowerCase()?.includes(searchInLow) ||
              list.description?.toLowerCase()?.includes(searchInLow) ||
              list?.keywords?.toString()?.toLowerCase()?.includes(searchInLow))
          );
        });
      }
      this.setState({ cardList: listOfCards });
    });
  }, 300);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  render() {
    return (
      <div className="parent-size">
        <div className="row justify-content-between">
          <DKLabel className="fs-l fw-m" text="Settings" />
          <div className="" style={{ width: 190 }}>
            <DKSearchBar
              className=""
              onSearch={(value: any) => {
                this.onSearch(value);
              }}
              searchText={this.state.searchText}
            />
          </div>
        </div>
      </div>
    );
  }
  getAllCards() {
    let arr = [];
    this.state?.cardList?.length > 0 &&
      this.state.cardList?.forEach((obj) => {
        if (obj.hidden) return;

        arr.push(this.getCard(obj));
      });
    return arr;
  }
  getCard(setting) {
    return (
      <div
        className="bg-white border-radius-m border-m p-r column border-box shadow-s mobile-parent-width"
        style={{ minWidth: 250, width: "24%" }}
        onClick={() => {}}
      >
        <DKIcon src={setting.icon} className="" />
        <DKLabel text={setting.title} className="fw-m mt-m fs-m" />
        <DKLabel
          text={setting.description}
          className="text-dark-gray mt-xs mb-auto"
          style={{ height: 30 }}
        />
        <DKButton
          title={setting.buttonTitle ? setting.buttonTitle : "View Details"}
          className="align-self-end bg-gray1 border-m mt-xl"
          icon={DKIcons.ic_arrow_right2}
          onClick={() => {
            if (setting.url) {
              window.open(setting.url, "_blank");
            } else {
              setting?.onClick();
            }
          }}
          isReverse={true}
        />
      </div>
    );
  }

  getFieldRequiredPopup = () => {
    return (
      <Popup
        className="hide-scroll-bar"
        popupWindowStyles={{
          width: 500,
          maxWidth: "auto",
          maxHeight: "90vh",
          padding: 16
        }}
      >
        <FieldMandatoryPopup
          onSave={(data) => {
            console.log(data);
            this.setState({ showRequiredFieldPopup: false });
          }}
          onCancel={() => {
            this.setState({ showRequiredFieldPopup: false });
          }}
        />
      </Popup>
    );
  };

  embedScriptPopup() {
    return (
      <div className="transparent-background">
        <div className="popup-window" style={{ maxWidth: 600 }}>
          <ChatEmbedPopup
            onClose={() => this.setState({ showEmbedPopup: false })}
          />
        </div>
      </div>
    );
  }

  getHelpSection() {
    return (
      <div className="column bg-deskera-secondary p-r border-radius-m border-m mt-m">
        <DKLabel text='<span style="font-size:20px">🙋‍♂️</span> Need help getting started? Learn more about CRM setting options.' />

        <div className="row mt-r ml-l">
          <DKButton
            className="border-m bg-white"
            title="Read"
            onClick={AppManager.showComingSoonWarning}
          />
          <DKButton
            className="border-m ml-r bg-white"
            title="Watch"
            onClick={AppManager.showComingSoonWarning}
          />
        </div>
      </div>
    );
  }
  getNotificationSettingPopup = () => {
    return (
      <NotificationSettings
        disabledBellNotification={this.state.disabledBellNotification}
        onSave={() => this.onSaveNotification()}
        onCancel={() => this.setState({ showNotificationPopup: false })}
      />
    );
  };
  onSaveNotification = () => {
    this.setState(
      { showNotificationPopup: !this.state.showNotificationPopup },
      () => this.getNotificationData()
    );
  };

  getActivitySettingPopup = () => {
    return (
      <ActivitySetting
        onCancel={() => this.setState({ showActivityPopup: false })}
      />
    );
  };
  getNoDataView = () => {
    return (
      <div
        className="column justify-self-center align-self-center align-items-center position-absolute parent-width"
        style={{ top: 200, pointerEvents: "none", right: 0 }}
      >
        <DKIcon src={ic_no_data} className="ic-l" style={{ opacity: 0.2 }} />
        <DKLabel text={"No data found"} className="fw-m text-gray mt-l" />
        <DKLabel
          text={"Once setting is available, it will appear here"}
          className="text-gray mt-s text-align-center"
        />
      </div>
    );
  };

  /** ************** USERS PERMISSION CARD *****************/
  getUserPermissionsPopup = () => {
    return (
      <Popup
        className="user-permission-popup"
        title={"User permissions"}
        allowClose={true}
        onClose={() => this.toggleUserPermissionsPopup(false)}
        popupWindowStyles={{
          maxWidth: "auto",
          maxHeight: "90vh",
          padding: 16,
          overflowY: "visible"
        }}
      >
        <UserPermission
          permittedActions={this.props.moduleWisePermissions[TABLES.PERMISSION]}
        />
      </Popup>
    );
  };

  toggleUserPermissionsPopup = (shouldOpen?: boolean) => {
    if (
      shouldOpen === false &&
      Utility.getQueryParam(this.props.location, "cardId")
    ) {
      /* Removing page param for Card Id, for allowing user to open UserPermissions from profileOptionsPopup */
      RouteManager.replacePageParams(PAGE_ROUTES.SETTINGS);
    }

    if (
      shouldOpen &&
      Utility.isEmptyObject(this.props.moduleWisePermissions[TABLES.PERMISSION])
    ) {
      showAlert(
        "No access!",
        "Looks like you don't have access for managing user permissions, please contact your organization owner to provide access.",
        [
          {
            title: "Ok",
            className: "bg-gray1 border-m",
            onClick: () => RouteManager.replacePageParams(PAGE_ROUTES.SETTINGS)
          }
        ]
      );
      return;
    }

    this.setState((prevState) => ({
      showUserPermissionsPopup:
        shouldOpen ?? !prevState.showUserPermissionsPopup
    }));
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /** ************** USERS TEAM PERMISSION CARD *****************/
  getTeamPermissionsPopup = () => {
    return (
      <Popup
        className="team-permission-popup"
        title={"Team permission"}
        allowClose={true}
        onClose={() => this.toggleTeamPermissionsPopup(false)}
        popupWindowStyles={{
          maxWidth: "auto",
          // minHeight: 550,
          maxHeight: "90vh",
          padding: 16,
          overflowY: "visible"
        }}
      >
        <TeamPermission
          permittedActions={this.props.moduleWisePermissions[TABLES.PERMISSION]}
        />
      </Popup>
    );
  };

  toggleTeamPermissionsPopup = (shouldOpen?: boolean) => {
    this.setState((prevState) => ({
      showTeamPermissionsPopup:
        shouldOpen ?? !prevState.showTeamPermissionsPopup
    }));
  };

  /** ************** BOOK DEMO Settings *****************/
  getBookDemoPopup = () => {
    return (
      <Popup
        className="book-demo-popup"
        title={"Book-A-Demo Settings"}
        allowClose={true}
        onClose={() => this.toggleBookDemoPopup(false)}
        popupWindowStyles={{
          width: 700,
          maxWidth: "90vw",
          maxHeight: "90vh",
          padding: 16,
          overflowY: "visible"
        }}
      >
        <BookDemoSettings onClose={() => this.toggleBookDemoPopup(false)} />
      </Popup>
    );
  };

  toggleBookDemoPopup = (shouldOpen?: boolean) => {
    this.setState((prevState) => ({
      showBookADemoPopup: shouldOpen ?? !prevState.showBookADemoPopup
    }));
  };

  /** ************** Duplicate check in modules Settings ***************/
  getDuplicateCheckPopup = () => {
    return <DuplicateCheckSetting />;
  };

  /** ************** Lead Platforms Integration Settings ***************/
  getEventScorePopup = () => {
    return (
      <EventScore
        onClose={() => this.setState({ showEventScorePopup: false })}
      />
    );
  };

  getFooter() {
    return (
      <div className="mt-xl parent-width mb-xxl align-items-center justify-content-center">
        <DKLabel className="text-align-center fw-m" text="Contact us" />
        <DKLabel
          className="text-align-center mt-s text-gray"
          text="If you have any other query or problem please feel free to contact us"
        />
        <DKLabel
          className="text-align-center text-underline text-blue mt-r"
          text={`<a href="mailto:${DESKERA_CARE_EMAIL}">${DESKERA_CARE_EMAIL}</a>`}
        />
        <DKButton
          className="bg-transparent border-none text-blue text-underline"
          title="View release notes"
          style={{ width: 130, margin: "16px auto 0" }}
          onClick={() => {
            commonCustomEvent.dispatch(COMMON_EVENTS.VIEW_RELEASE_NOTE, {});
          }}
        />
      </div>
    );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////

  showFeedbackPopup = () => {
    this.setState({ needFeedbackPopup: true });
  };
  hideFeedbackPopup = () => {
    this.setState({ needFeedbackPopup: false });
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////////////////
  checkFormQueryParams = (props) => {
    const code = Utility.getQueryParam(props.location, "code");
    const state = Utility.getQueryParam(props.location, "state");
    const scope = Utility.getQueryParam(props.location, "scope");
    if (code && state && scope) {
      Stripe.addStripeAccount({ code, state })
        .then((data) => {
          this.props.fetchStripeAccount();
          RouteManager.navigateToHome();
        })
        .catch((err) => {
          RouteManager.navigateToHome();
        });
    } else {
      RouteManager.navigateToHome();
    }
  };
}
const mapStateToProps = (state) => ({
  isStripeConnected: state.userPref.isStripeConnected,
  moduleWisePermissions:
    state.rolesPermission?.currentUserModuleWisePermissions || {},
  teams: state.tenant.teams,
  teamPermissions: state.tenant.teamPermissions,
  indiaMartAccounts: state.userPref.indiaMartAccounts,
  justDialAccounts: state.userPref.justDialAccounts,
  bdrData: state.tenant.bdrData
});
const mapDispatchToProps = {
  fetchStripeAccount,
  fetchIndiaMartAccounts,
  fetchJustDialAccounts,
  getAccountsByGateway,
  fetchTeams
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Settings);
